<template>
    <p-secure require-admin>
        <p-form ref="form" @submit="onSubmit">
            <div class="container-fluid content-wrapper" v-if="webOrder">
                <b-row class="content-heading">
                    <b-col>
                        <div>Web Order Details</div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button
                            variant="primary"
                            :is-busy="isBusy || isRefreshBusy"
                            type="submit"
                        >
                            Save
                        </p-button>
                    </b-col>
                </b-row>
                <p-card>
                    <b-row>
                        <b-col>
                            <div class="mb-2">
                                <h4>
                                    Order Status:
                                    {{ webOrder.webOrderStatus }}
                                    <b-overlay :show="isBusy || isRefreshBusy"
                                       rounded
                                       opacity="0.5"
                                       spinner-small
                                       spinner-variant="primary"
                                       class="d-inline-block">
                                    <b-dropdown
                                        class="ml-2"
                                        text="Close Order"
                                        variant="danger"
                                        :disabled="
                                            webOrder.webOrderStatus.toLowerCase() ==
                                                'complete' ||
                                                webOrder.webOrderStatus.toLowerCase() ==
                                                    'cancelled' ||
                                                webOrder.webOrderStatus.toLowerCase() ==
                                                    'completed at register'
                                        "
                                    >
                                        <b-dropdown-item
                                            @click="
                                                handleCancelOrder('cancelOrder')
                                            "
                                            >Cancel Order</b-dropdown-item
                                        >
                                        <b-dropdown-item
                                            :disabled="
                                                webOrder.webOrderStatus.toLowerCase() ==
                                                    'partial' ||
                                                    webOrder.webOrderStatus.toLowerCase() ==
                                                        'backordered' ||
                                                    webOrder.webOrderStatus.toLowerCase() ==
                                                        'failed' ||
                                                    webOrder.webOrderStatus.toLowerCase() ==
                                                        'partial/backordered'
                                            "
                                            @click="
                                                handleCancelOrder(
                                                    'completeAtRegister'
                                                )
                                            "
                                            >Completed at
                                            Register</b-dropdown-item
                                        >
                                    </b-dropdown>
                                    </b-overlay>
                                    <p-button
                                        class="ml-2"
                                        v-if="
                                            webOrder.webOrderStatus.toLowerCase() ==
                                                'cancelled' ||
                                                webOrder.webOrderStatus.toLowerCase() ==
                                                    'completed at register'
                                        "
                                        @click="handleReopen"
                                        :is-busy="isBusy || isRefreshBusy"
                                        >Reopen</p-button
                                    >
                                </h4>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-lg="3">
                        <b-col>
                            <p-card>
                                <div style="height: 160px;">
                                    <span>
                                        <strong>Web Order</strong><br />
                                        {{ webOrder.name }} <br />
                                        Order Date:
                                        {{
                                            datetimeToLocalTimeFormatter(
                                                webOrder.createDate
                                            )
                                        }}
                                        <br />
                                        Last Update: {{ getLastUpdateDate }}
                                        <br />
                                        <span class="d-flex">
                                            Payment:
                                            {{
                                                webOrder.paymentMethod
                                                    .paymentMethodName
                                            }}
                                            <span
                                                v-if="
                                                    (webOrder.webOrderStatus.toLowerCase() ===
                                                        'open' &&
                                                        (webOrder.paymentMethod
                                                            .isARAccount ||
                                                            webOrder
                                                                .paymentMethod
                                                                .isCCAccount)) ||
                                                        (webOrder.webOrderStatus.toLowerCase() ===
                                                            'failed' &&
                                                            webOrder
                                                                .saleShipments
                                                                .length == 0 &&
                                                            (webOrder
                                                                .paymentMethod
                                                                .isARAccount ||
                                                                webOrder
                                                                    .paymentMethod
                                                                    .isCCAccount))
                                                "
                                                ><ARAccountsModal
                                                    :customer="
                                                        webOrder.customer
                                                    "
                                                    :webOrder="webOrder"
                                                    @addaccount="
                                                        webOrderpaymentMethod
                                                    "
                                                    :is-busy="isBusy || isRefreshBusy"
                                                />
                                            </span
                                        >
                                         <span
                                                v-if="
                                                   (webOrder.webOrderStatus.toLowerCase() ===
                                                        'open' && webOrder.paymentMethod.isDCAccount && webOrder.isARAccountValid
                                                        || (webOrder.webOrderStatus.toLowerCase() ===
                                                        'failed' && webOrder.saleShipments.length == 0 && webOrder.paymentMethod
                                                            .isDCAccount  && webOrder.isARAccountValid)
                                                 )
                                                        
                                                "
                                                >
                                                 <DepartmentChargeModal
                                                    :webOrder="webOrder
                                                    "
                                                    @loadData="
                                                        loadData
                                                    "
                                                    :is-web-order-busy="isBusy || isRefreshBusy"
                                                    /></span>
                                        </span>
                                        <span
                                            v-if="
                                                webOrder.paymentMethod
                                                    .isARAccount
                                            "
                                        >
                                            Account Name:
                                            {{
                                                webOrder.paymentMethod
                                                    .accountNumber
                                            }}<br />
                                            Agency Name:
                                            {{
                                                webOrder.paymentMethod
                                                    .agencyName
                                            }}
                                        </span>
                                        <span
                                            class="d-flex"
                                            v-if="!webOrder.isARAccountValid"
                                        >
                                            <span
                                                style="color: red; margin-top: -5px;"
                                                >Payment method is
                                                invalid!</span
                                            >
                                        </span>
                                        <span
                                            class="d-flex"
                                            v-else-if="
                                                !webOrder.isDCAccountValid
                                            "
                                        >
                                            <span
                                                style="color: red; margin-top: -5px;"
                                                >Department Charge details are
                                                missing!</span
                                            >
                                        </span>
                                        <span
                                            class="d-flex"
                                            v-if="webOrder.isTaxExempt"
                                        >
                                            <span style="color: red;"
                                                >TAX EXEMPT</span
                                            >
                                        </span>
                                    </span>
                                </div>
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card>
                                <div style="height: 160px;">
                                    <span>
                                        <strong>Customer</strong><br />
                                        {{ webOrder.customer.firstName }}
                                        {{ webOrder.customer.lastName }}<br />
                                        <span v-if="webOrder.customer.email"
                                            >{{ webOrder.customer.email }} <br
                                        /></span>
                                        <span
                                            v-if="
                                                webOrder.customer.phone.number
                                            "
                                            >{{
                                                phoneFormatter(
                                                    webOrder.customer.phone
                                                )
                                            }}
                                            <br
                                        /></span>
                                        <span v-if="webOrder.customer.number">{{
                                            webOrder.customer.number
                                        }}</span>
                                        <p-button
                                            class="ml-1"
                                            v-b-modal.editCustomer
                                            :disabled="
                                                webOrder.webOrderStatus.toLowerCase() != 'open'
                                                || !webOrder.customer.isActive
                                                || isBusy
                                            "
                                            no-border
                                            :is-busy="isBusy || isRefreshBusy"
                                        >
                                            <b-icon-pencil-square>
                                            </b-icon-pencil-square>
                                        </p-button>
                                        <br />
                                        <p-feature-flag name="Customer Profile">
                                            <b
                                                v-if="
                                                    !webOrder.customer.isActive
                                                "
                                                style="color:red;"
                                                >Customer is inactive</b
                                            >
                                        </p-feature-flag>
                                    </span>
                                </div>
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card>
                                <div style="height: 160px;">
                                    <span>
                                        <p-address-input
                                            :key="webOrder.name"
                                            v-model="webOrder.shippingAddress"
                                            :disabled="
                                                webOrder.webOrderStatus.toLowerCase() ==
                                                    'complete' ||
                                                    webOrder.webOrderStatus.toLowerCase() ==
                                                        'cancelled' ||
                                                    isBusy
                                            "
                                            text-area
                                            label="Shipping Address"
                                            :is-busy="isBusy || isRefreshBusy"
                                            @updateShipping="
                                                        onSubmit
                                                    "
                                        >
                                        </p-address-input>
                                        <span
                                            >Method:
                                            {{ webOrder.shipmentMethod }}</span
                                        >
                                    </span>
                                </div>
                            </p-card>
                        </b-col>
                    </b-row>
                    <p-card>
                        <h4>
                            Internal Order Fulfillment Notes
                            <span
                                class="text-normal text-sm"
                                v-if="webOrder.internalOrderNote.noteId"
                            >
                                Last updated
                                {{
                                    webOrder.internalOrderNote.modifiedDate ==
                                    null
                                        ? 'unknown'
                                        : datetimeToLocalTimeFormatter(
                                              webOrder.internalOrderNote
                                                  .modifiedDate
                                          ).replace(', ', ' at ')
                                }}
                                by
                                {{
                                    webOrder.internalOrderNote.userId == null
                                        ? 'Unknown'
                                        : webOrder.internalOrderNote.user
                                              .displayName
                                }}.
                            </span>
                        </h4>
                        <p-text-area
                            :shortExpandableFormat="true"
                            :placeholder="
                                'Comments written here are not visible to the customer'
                            "
                            v-model="webOrder.internalOrderNote.text"
                        />
                    </p-card>
                    <p-card
                        v-if="
                            webOrder.webOrderStatus.toLowerCase() != 'open' &&
                                webOrder.webOrderStatus.toLowerCase() !=
                                    'complete' &&
                                webOrder.webOrderStatus.toLowerCase() !=
                                    'cancelled' &&
                                webOrder.webOrderStatus.toLowerCase() !=
                                    'completed at register'
                        "
                    >
                        <b-row class="mb-2">
                            <b-col cols="auto">
                                <h4>Status: {{ webOrder.webOrderStatus }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col cols="12" class="d-flex">
                                <p-button
                                    variant="primary"
                                    :disabled="noQtyToFill() || !webOrder.customer.isActive || !webOrder.isDCAccountValid || isBusy"
                                    @click="validateWorkOrder()"
                                    :is-busy="isBusy || isRefreshBusy"
                                    >Work Order</p-button
                                >
                                <template v-if="canAddProducts">
                                    <ProductMultiSelectQuickSearch
                                        is-active-filter
                                        include-products
                                        include-terms
                                        icon-plus
                                        label="Add Product(s)"
                                        title="Add Product to Web Order"
                                        name="AddToWebOrder"
                                        @selections="addSelectedItems"
                                        fieldSet="webOrderFields"
                                        class="ml-2 mr-2"
                                        :is-busy="isBusy || isRefreshBusy"
                                    />
                                </template>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p-table
                                    :items="webOrder.partialSales"
                                    :fields="partialSaleFields"
                                    :enable-row-selection="false"
                                >
                                    <template #row-details="row">
                                        <div v-if="row.item.comments" class="">
                                            Comments:
                                            {{ row.item.comments }}
                                        </div>
                                    </template>
                                    <template
                                        v-slot:cell(retailPrice)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <div v-if="item.isOnSale">
                                            <s>{{ value }}</s>
                                            <div>
                                                {{
                                                    currencyFormatter(
                                                        item.itemPrice
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div v-else>{{ value }}</div>
                                    </template>
                                    <template
                                        v-slot:cell(taxTotal)="{ value, item }"
                                    >
                                        <div>
                                            {{
                                                currencyFormatter(
                                                    calcExtTaxPartialSale(item)
                                                )
                                            }}
                                        </div>
                                    </template>
                                </p-table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="5">
                                <p-text-area
                                    label="Shipping Instructions"
                                    v-model="webOrder.shippingInstructions"
                                    :disabled="true"
                                />
                                <p-text-area
                                    label="Special Comments"
                                    v-model="webOrder.specialComments"
                                    :disabled="true"
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                    <div v-if="webOrder.webOrderStatus.toLowerCase() != 'open'">
                        <p-card
                            v-for="saleShipment in webOrder.saleShipments"
                            :key="saleShipment.SaleFulfillmentId"
                        >
                            <b-row class="mb-2">
                                <b-col cols="auto">
                                    <h4>
                                        Shipment Date:
                                        {{
                                            dateFormatter(
                                                saleShipment.processDate
                                            )
                                        }}
                                    </h4>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col>
                                    <p-button
                                        variant="outline-primary"
                                        @click="exportShipment(saleShipment, 1)"
                                        :is-busy="isPackingBusy"
                                        >Packing List</p-button
                                    >
                                    <p-button
                                        variant="outline-primary"
                                        class="ml-2"
                                        @click="exportShipment(saleShipment, 0)"
                                        :is-busy="isReceiptBusy"
                                        ><i class="fas fa-print"></i>
                                        Receipt</p-button
                                    >
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-table
                                        :items="
                                            saleShipment.saleShippingProducts
                                        "
                                        :fields="SaleShipmentsFields"
                                        :enable-row-selection="false"
                                    >
                                        <template #row-details="row">
                                            <div
                                                v-if="row.item.comments"
                                                class=""
                                            >
                                                Comments:
                                                {{ row.item.comments }}
                                            </div>
                                        </template>
                                        <template
                                            v-slot:cell(retailPrice)="{
                                                value,
                                                item
                                            }"
                                        >
                                            <div v-if="item.isOnSale">
                                                <s>{{ value }}</s>
                                                <div>
                                                    {{
                                                        currencyFormatter(
                                                            item.itemPrice
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div v-else>{{ value }}</div>
                                        </template>
                                    </p-table>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" lg="5">
                                    <p-text-area
                                        label="Shipping Instructions"
                                        v-model="webOrder.shippingInstructions"
                                        :disabled="true"
                                    />
                                    <p-text-area
                                        label="Special Comments"
                                        v-model="webOrder.specialComments"
                                        :disabled="true"
                                    />
                                </b-col>
                                <b-col class="ml-auto" cols="12" lg="5">
                                    <b-row>
                                        <b-col cols="9">
                                            <strong>Shipment Subtotal:</strong
                                            ><br /><br />
                                            <strong>Shipping & Handling:</strong
                                            ><br /><br />

                                            <strong>Shipping Tax:</strong
                                            ><br /><br />
                                            <strong>Extended Tax Total:</strong
                                            ><br /><br />
                                            <strong>Grand Total:</strong
                                            ><br /><br />
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <strong>{{
                                                currencyFormatter(
                                                    saleShipment.subTotal
                                                )
                                            }}</strong
                                            ><br /><br />
                                            <strong>{{
                                                currencyFormatter(
                                                    saleShipment.shippingTotal
                                                )
                                            }}</strong
                                            ><br /><br />
                                            <strong>{{
                                                currencyFormatter(
                                                    saleShipment.shippingTax
                                                )
                                            }}</strong
                                            ><br /><br />
                                            <strong>{{
                                                currencyFormatter(
                                                    saleShipment.extendedTaxTotal
                                                )
                                            }}</strong
                                            ><br /><br />
                                            <strong>{{
                                                currencyFormatter(
                                                    saleShipment.grandTotal
                                                )
                                            }}</strong
                                            ><br /><br />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </p-card>
                    </div>
                    <p-card>
                        <b-row class="mb-2">
                            <b-col cols="auto">
                                <h4>
                                    Original Order:
                                    {{ dateFormatter(webOrder.createDate) }}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                v-if="
                                    webOrder.webOrderStatus.toLowerCase() ==
                                        'open'
                                "
                                class="d-flex mb-2"
                                cols="12"
                            >
                                <p-button
                                    variant="primary"
                                    @click="validateWorkOrder()"
                                    :disabled="!this.webOrder.isARAccountValid || !webOrder.customer.isActive || !webOrder.isDCAccountValid"
                                    :is-busy="isBusy || isRefreshBusy"
                                    >Work Order</p-button
                                >
                                <ProductMultiSelectQuickSearch
                                    is-active-filter
                                    include-products
                                    include-terms
                                    icon-plus
                                    label="Add Product(s)"
                                    title="Add Product to Web Order"
                                    name="AddToWebOrder"
                                    @selections="addSelectedItems"
                                    fieldSet="webOrderFields"
                                    class="ml-2 mr-2"
                                    :is-busy="isBusy  || isRefreshBusy"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p-table
                                    :items="webOrder.products"
                                    :fields="productFields"
                                    :enable-row-selection="false"
                                >
                                    <template #row-details="row">
                                        <div v-if="row.item.comments" class="">
                                            Comments:
                                            {{ row.item.comments }}
                                        </div>
                                    </template>
                                    <template
                                        v-slot:cell(retailPrice)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <div v-if="item.isOnSale">
                                            <s>{{ value }}</s>
                                            <div>
                                                {{
                                                    currencyFormatter(
                                                        item.itemPrice
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div v-else>{{ value }}</div>
                                    </template>
                                </p-table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="5">
                                <p-text-area
                                    label="Shipping Instructions"
                                    v-model="webOrder.shippingInstructions"
                                    :disabled="true"
                                />
                                <p-text-area
                                    label="Special Comments"
                                    v-model="webOrder.specialComments"
                                    :disabled="true"
                                />
                            </b-col>
                            <b-col class="ml-auto" cols="12" lg="5">
                                <b-row>
                                    <b-col cols="9">
                                        <strong>Order Total:</strong
                                        ><br /><br />
                                        <strong>Shipping & Handling:</strong
                                        ><br /><br />
                                        <strong>Shipping Tax:</strong
                                        ><br /><br />
                                        <strong>Extended Tax Total:</strong
                                        ><br /><br />
                                        <strong>Grand Total:</strong
                                        ><br /><br />
                                    </b-col>
                                    <b-col cols="3" class="text-right">
                                        <strong>{{
                                            currencyFormatter(webOrder.subtotal)
                                        }}</strong
                                        ><br /><br />
                                        <strong>{{
                                            currencyFormatter(
                                                webOrder.shippingTotal
                                            )
                                        }}</strong
                                        ><br /><br />
                                        <strong>{{
                                            currencyFormatter(
                                                webOrder.shippingTax
                                            )
                                        }}</strong
                                        ><br /><br />
                                        <strong>{{
                                            currencyFormatter(
                                                webOrder.extendedTaxTotal
                                            )
                                        }}</strong
                                        ><br /><br />
                                        <strong>{{
                                            currencyFormatter(webOrder.total)
                                        }}</strong
                                        ><br /><br />
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </p-card>
                </p-card>
                <b-row>
                    <b-col class="mt-2 mb-2 text-center">
                        <p-button
                            variant="primary"
                            :is-busy="isBusy  || isRefreshBusy"
                            type="submit"
                        >
                            Save
                        </p-button>
                    </b-col>
                </b-row>
                <p-modal
                    name="editCustomer"
                    label="Customer Name"
                    size="md"
                    no-close-on-backdrop
                    no-close-esc
                    @ok.prevent="handleAddCustomer"
                    @show="showModal"
                    ref="editCustomerModal"
                >
                    <template v-slot:default>
                        <p-form ref="formModal">
                            <div>
                                <b-row>
                                    <b-col>
                                        {{ webOrder.customer.firstName }}
                                        {{ webOrder.customer.lastName }}
                                        <span v-if="webOrder.customer.email"
                                            >{{ webOrder.customer.email }}
                                        </span>
                                        <span v-if="webOrder.customer.phone"
                                            >{{
                                                webOrder.customer.phone.number
                                            }}
                                        </span>
                                        <span v-if="webOrder.customer.number">{{
                                            webOrder.customer.number
                                        }}</span>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col>
                                        <p-advanced-select
                                            label="Customer"
                                            data-type="customers"
                                            v-model="
                                                webOrder.customer.customerId
                                            "
                                            rules="required"
                                        />
                                    </b-col>
                                </b-row>
                            </div>
                        </p-form>
                    </template>
                </p-modal>
                <p-modal
                    name="workOrder"
                    label=""
                    size="xl"
                    hide-footer
                    no-close-on-backdrop
                    @show="showWorkOrderModal"
                    @hide="hideWorkOrderModal"
                    ref="workOrderModal"
                >
                    <template v-slot:modal-title>
                        <b-row class="mb-2">
                            <b-col cols="auto">
                                <strong>Order Date: </strong
                                >{{ dateFormatter(webOrder.createDate) }}

                                <strong class="ml-2">Shipping Address: </strong>
                                {{
                                    addressFormatter(
                                        webOrder.shippingAddress,
                                        $store
                                    )
                                }}

                                <br />
                                <strong>Status: </strong
                                >{{ webOrder.webOrderStatus }}

                                <br />
                                <strong>Tax Calculation Last Updated: </strong
                                ><span
                                    :class="{
                                        redColor:
                                            webOrder.taxServiceLastUpdate ==
                                            null
                                    }"
                                    >{{
                                        webOrder.taxServiceLastUpdate == null
                                            ? 'Never'
                                            : datetimeToLocalTimeFormatter(
                                                  webOrder.taxServiceLastUpdate
                                              )
                                    }}</span
                                >
                                <p-button
                                    v-if="
                                        webOrder.webOrderStatus.toLowerCase() ==
                                            'open' && !webOrder.isTaxExempt
                                    "
                                    class="s ml-2"
                                    variant="primary"
                                    @click="refreshAllTaxes"
                                    :is-busy="isBusy  || isRefreshBusy"
                                    >Refresh All Taxes
                                </p-button>
                                <br />
                                <span
                                    v-if="webOrder.isTaxExempt"
                                    style="color: red;"
                                    >Account is Tax Exempt</span
                                >
                            </b-col>
                        </b-row>
                    </template>
                    <template v-slot:default>
                        <p-form ref="formModal">
                            <div>
                                <b-row v-if="refreshIsBusy">
                                    <b-col>
                                        <p-table
                                            :items="workOrder.partialSales"
                                            :fields="workOrderFields"
                                            :enable-row-selection="false"
                                            ref="partialSalesTable"
                                        >
                                            <template #row-details="row">
                                                <span>
                                                    <div
                                                        v-if="row.item.comments"
                                                        class=""
                                                    >
                                                        Comments:
                                                        {{ row.item.comments }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            row.item
                                                                .isSerialized
                                                        "
                                                        class=""
                                                    >
                                                        <br />
                                                        <p-table
                                                            :items="
                                                                row.item
                                                                    .serialNumbers
                                                            "
                                                            :fields="[
                                                                {
                                                                    key:
                                                                        'label',
                                                                    tdClass:
                                                                        'text-right'
                                                                },
                                                                {
                                                                    key:
                                                                        'serialNumber',
                                                                    tdClass:
                                                                        'text-left'
                                                                }
                                                            ]"
                                                            :enable-row-selection="
                                                                false
                                                            "
                                                            thead-class="d-none"
                                                            :show-record-count="
                                                                false
                                                            "
                                                            class="overflow-hidden"
                                                            empty-text=""
                                                        >
                                                            <template
                                                                v-slot:cell(label)="{}"
                                                            >
                                                                <strong
                                                                    >Serial
                                                                    Number:</strong
                                                                >
                                                            </template>
                                                            <template
                                                                v-slot:cell(serialNumber)="{
                                                                    value,
                                                                    item
                                                                }"
                                                            >
                                                                <p-input
                                                                    name="Serial Number"
                                                                    v-model="
                                                                        item.serialNumber
                                                                    "
                                                                    :rules="{
                                                                        required: true,
                                                                        max: {
                                                                            length: 25
                                                                        },
                                                                        isUnique: {
                                                                            isUniqueValue:
                                                                                item.isUnique,
                                                                            message:
                                                                                'This serial number is already in use for this order.'
                                                                        },
                                                                        'is-valid-serial-number': {
                                                                            isValidValue:
                                                                                item.isValid
                                                                        }
                                                                    }"
                                                                    @change="
                                                                        updateSerialValidation(
                                                                            row.item,
                                                                            value,
                                                                            item
                                                                        )
                                                                    "
                                                                    class="ml-3 tcw200"
                                                                    mode="lazy"
                                                                >
                                                                </p-input>
                                                            </template>
                                                        </p-table>
                                                    </div>
                                                </span>
                                            </template>
                                            <template
                                                v-slot:cell(retailPrice)="{
                                                    value,
                                                    item
                                                }"
                                            >
                                                <div v-if="item.isOnSale">
                                                    <s>{{ value }}</s>
                                                    <div>
                                                        {{
                                                            currencyFormatter(
                                                                item.itemPrice
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else>{{ value }}</div>
                                            </template>
                                            <template
                                                v-slot:cell(shippedQty)="{
                                                    value,
                                                    item
                                                }"
                                            >
                                                <p-number
                                                    v-model.number="item.shippedQty"
                                                    @change="fulfilledQtyChanged($event, item)"
                                                    narrow-input
                                                    name="Fulfilled Qty"
                                                    input-type="integer"
                                                    :rules="getShippedQtyRules(item)"
                                                    compact-format
                                                >
                                                </p-number>
                                            </template>
                                            <template
                                                v-slot:cell(cancelledQty)="{
                                                    value,
                                                    item
                                                }"
                                            >
                                                <p-number
                                                    v-model.number="
                                                        item.cancelledQty
                                                    "
                                                    @change="
                                                        item.cancelledQty = zeroIfNullFormatter(
                                                            $event
                                                        )
                                                    "
                                                    input-type="integer"
                                                    name="Cancelled Qty"
                                                    narrow-input
                                                    :rules="
                                                        getCancelledQtyRules(
                                                            item
                                                        )
                                                    "
                                                    compact-format
                                                >
                                                </p-number>
                                            </template>
                                            <template
                                                v-slot:cell(tax)="{
                                                    value,
                                                    item
                                                }"
                                            >
                                                <p-number
                                                    :value="
                                                        item.manualOverrideUserInput
                                                    "
                                                    @change="
                                                        handleItemTaxOverride(
                                                            $event,
                                                            item
                                                        )
                                                    "
                                                    narrow-input
                                                    input-type="money"
                                                    name="Tax"
                                                    rules="min_value:0"
                                                    :disabled="
                                                        webOrder.isTaxExempt
                                                    "
                                                    compact-format
                                                >
                                                </p-number>
                                            </template>
                                            <template
                                                v-slot:cell(backorderedQty)="{
                                                    value,
                                                    item
                                                }"
                                            >
                                                <p-number
                                                    v-model.number="
                                                        item.backorderedQty
                                                    "
                                                    @change="
                                                        item.backorderedQty = zeroIfNullFormatter(
                                                            $event
                                                        )
                                                    "
                                                    input-type="integer"
                                                    name="Back Order Qty"
                                                    narrow-input
                                                    :rules="
                                                        getBackOrderQtyRules(
                                                            item
                                                        )
                                                    "
                                                    compact-format
                                                >
                                                </p-number>
                                            </template>
                                        </p-table>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" lg="5">
                                        <p-text-area
                                            label="Shipping Instructions"
                                            v-model="
                                                workOrderShippingInstructions
                                            "
                                            name="Shipping Instructions"
                                            :disabled="false"
                                        />
                                        <p-text-area
                                            label="Special Comments"
                                            v-model="workOrderSpecialComments"
                                            name="Special Comments"
                                            :disabled="false"
                                        />
                                    </b-col>
                                    <b-col class="ml-auto" cols="12" lg="5">
                                        <b-row>
                                            <b-col cols="9" lg="8" xl="9">
                                                <strong
                                                    >Shipment Subtotal:</strong
                                                ><br /><br />
                                                <strong
                                                    >Shipping &
                                                    Handling:</strong
                                                ><br /><br />
                                                <strong>Shipping Tax:</strong
                                                ><br /><br />
                                                <strong>Item Tax:</strong
                                                ><br /><br />
                                                <strong>Grand Total:</strong
                                                ><br /><br />
                                            </b-col>
                                            <b-col
                                                cols="3"
                                                lg="4"
                                                xl="3"
                                                class="text-right"
                                            >
                                                <strong>{{
                                                    currencyFormatter(
                                                        calcWorkOrderSubTotal
                                                    )
                                                }}</strong
                                                ><br /><br />
                                                <p-number
                                                    v-if="canAddShippingFee"
                                                    input-type="money"
                                                    name="shippingAndHandling"
                                                    v-model.number="
                                                        workOrderShippingFees
                                                    "
                                                    rules="min_value:0"
                                                    compact-format
                                                    class="mb-3"
                                                />
                                                <div v-else>
                                                    <strong>
                                                        N/A
                                                    </strong>
                                                    <br /><br />
                                                </div>
                                                <p-number
                                                    v-if="canAddShippingFee"
                                                    input-type="money"
                                                    name="Shipping Tax"
                                                    v-model.number="
                                                        workOrder.manualOverrideUserInput
                                                    "
                                                    @blur="
                                                        handleShippingTaxOverride(
                                                            $event,
                                                            workOrder
                                                        )
                                                    "
                                                    rules="min_value:0"
                                                    :disabled="
                                                        webOrder.isTaxExempt
                                                    "
                                                    compact-format
                                                    class="mb-3"
                                                />
                                                <div v-else>
                                                    <strong>
                                                        N/A
                                                    </strong>
                                                    <br /><br />
                                                </div>
                                                <strong>{{
                                                    currencyFormatter(
                                                        calcWorkOrderItemTaxTotal
                                                    )
                                                }}</strong
                                                ><br /><br />
                                                <strong>{{
                                                    currencyFormatter(
                                                        calcWorkOrderTotal
                                                    )
                                                }}</strong>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="mb-2" v-if="processFailed">
                                    <b-col>
                                        <span
                                            v-if="
                                                requestedAmount > approvedAmount
                                            "
                                            style="color:red"
                                            >Requested Amount
                                            {{
                                                currencyFormatter(
                                                    requestedAmount
                                                )
                                            }}
                                            is greater than the Authorized
                                            Amount
                                            {{
                                                currencyFormatter(
                                                    approvedAmount
                                                )
                                            }}
                                            (attempt #
                                            {{ failedAuthAttempts }})</span
                                        >
                                        <span v-else>
                                            <span
                                                v-if="failedAuthAttempts < 5"
                                                style="color:red"
                                                >Credit card processing has
                                                failed. Please cancel order or
                                                try again (attempt #
                                                {{ failedAuthAttempts }})</span
                                            >
                                            <span
                                                v-if="failedAuthAttempts >= 5"
                                                style="color:red"
                                                >Processing attempts exceeded.
                                                You may only process $0.00
                                                (attempt #
                                                {{ failedAuthAttempts }})</span
                                            >
                                        </span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <p-button
                                            variant="danger"
                                            @click="processCancelShipment"
                                            :disabled="!processFailed"
                                            :is-busy="isBusy  || isRefreshBusy"
                                            >Cancel Shipment</p-button
                                        >
                                        <p-button
                                            class="float-right"
                                            variant="primary"
                                            @click="processWorkOrder"
                                            :disabled="disabledProcess"
                                            :is-busy="isBusy  || isRefreshBusy"
                                            >Process
                                            <strong>{{
                                                currencyFormatter(
                                                    calcWorkOrderTotal
                                                )
                                            }}</strong></p-button
                                        >
                                    </b-col>
                                </b-row>
                            </div>
                        </p-form>
                    </template>
                </p-modal>
            </div>
        </p-form>
    </p-secure>
</template>
<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import {
    phoneFormatter,
    currencyFormatter,
    roundingFormatter,
    bookVariantFormatter,
    dateFormatter,
    datetimeToLocalTimeFormatter
} from '@/components/Common/Formatters.js';
import Vue from 'vue';
import { extend } from 'vee-validate';
import unionBy from 'lodash/unionBy';
import { addressFormatter } from '@/components/Common/Formatters.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import ARAccountsModal from './ARAccountsModal.vue';
import DepartmentChargeModal from './DepartmentChargeModal.vue';

extend('exceed-shipped-qty', {
    message: () => {
        return 'Work Order Qty exceeds Qty to Fill';
    },

    validate: (value, { orderQty, cancelledQty }) => {
        return (
            -1 <
            (parseInt(orderQty) || 0) -
                (parseInt(value) || 0) -
                (parseInt(cancelledQty) || 0)
        );
    },
    params: ['orderQty', 'cancelledQty']
});
extend('exceed-cancelled-qty', {
    message: () => {
        return 'Work Order Qty exceeds Qty to Fill';
    },

    validate: (value, { orderQty, shippedQty }) => {
        return (
            -1 <
            (parseInt(orderQty) || 0) -
                (parseInt(shippedQty) || 0) -
                (parseInt(value) || 0)
        );
    },
    params: ['orderQty', 'shippedQty']
});
extend('exceed-back-order-qty', {
    message: () => {
        return 'Qty to Fill is met';
    },

    validate: (value, { orderQty, cancelledQty, shippedQty }) => {
        return (
            (parseInt(value) || 0) <=
            (parseInt(orderQty) || 0) -
                (parseInt(cancelledQty) || 0) -
                (parseInt(shippedQty) || 0)
        );
    },
    params: ['orderQty', 'cancelledQty', 'shippedQty']
});
extend('is-valid-serial-number', {
    message: () => {
        return 'Invalid serial number.';
    },
    validate: async (value, { isValidValue }) => {
        return isValidValue == undefined || isValidValue;
    },
    params: ['isValidValue']
});

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            isBusy: false,
            isRefreshBusy:false,
            refreshIsBusy: true,
            isReceiptBusy: false,
            isPackingBusy: false,
            webOrder: null,
            customerId: null,
            workOrder: {
                saleId: 0,
                name: 0,
                shippingTax: 0,
                shippingTaxOverrideId: null,
                shippingTaxOverride: null,
                shippingTotal: 0,
                itemTaxTotal: 0,
                total: 0,
                subTotal: 0,
                shippingInstructions: null,
                specialComments: null,
                saleFulfillmentId: 0,
                partialSales: [],
                isTaxExempt: false,
                paymentMethod: null,
            },
            workOrderShippingFees: 0,
            workOrderShippingTax: 0,
            workOrderItemTaxTotal: 0,
            workOrderShippingInstructions: null,
            workOrderSpecialComments: null,
            processFailed: false,
            formIsDirty: false,
            failedAuthAttempts: 0,
            requestedAmount: 0,
            approvedAmount: 0,
            filters: {
                saleId: 0,
                saleFulfillmentId: 0,
                exportOption: 0 // 0 - receipt; 1 - packing slip
            },
            productFields: [
                {
                    key: 'skuProduct',
                    label: 'Product SKU'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'type',
                    label: 'Variants',
                    formatter: (value, key, item) => {
                        if (item.bookVariant == 1 || item.bookVariant == 2) {
                            return bookVariantFormatter(item.bookVariant);
                        } else {
                            return (
                                item.gmVariant1 +
                                (item.gmVariant1 ? '/' : '') +
                                item.gmVariant2
                            );
                        }
                    }
                },
                {
                    key: 'retailPrice',
                    label: 'Item Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'orderQty',
                    label: 'Web Order Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'shippedQty',
                    label: 'Fulfilled Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cancelledQty',
                    label: 'Cancelled Qty ',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'extendedPrice',
                    label: 'Extended Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'taxTotal',
                    label: 'Extended Tax',
                    formatter: (value, key, item) => {
                        return currencyFormatter(item.taxTotal);
                    },
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'backorderedQty',
                    label: 'Back Order Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'serialNumber',
                    label: 'Serial Number: '
                }
            ],
            SaleShipmentsFields: [
                {
                    key: 'skuProduct',
                    label: 'Product SKU'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'type',
                    label: 'Variants',
                    formatter: (value, key, item) => {
                        if (item.bookVariant == 1 || item.bookVariant == 2) {
                            return bookVariantFormatter(item.bookVariant);
                        } else {
                            return (
                                item.gmVariant1 +
                                (item.gmVariant1 ? '/' : '') +
                                item.gmVariant2
                            );
                        }
                    }
                },
                {
                    key: 'retailPrice',
                    label: 'Item Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'orderQty',
                    label: 'Web Order Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'shippedQty',
                    label: 'Fulfilled Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'extendedPrice',
                    label: 'Extended Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'taxTotal',
                    label: 'Extended Tax',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ],
            partialSaleFields: [
                {
                    key: 'skuProduct',
                    label: 'Product SKU'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'type',
                    label: 'Variants',
                    formatter: (value, key, item) => {
                        if (item.bookVariant == 1 || item.bookVariant == 2) {
                            return bookVariantFormatter(item.bookVariant);
                        } else {
                            return (
                                item.gmVariant1 +
                                (item.gmVariant1 ? '/' : '') +
                                item.gmVariant2
                            );
                        }
                    }
                },
                {
                    key: 'retailPrice',
                    label: 'Item Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'orderQty',
                    label: 'Qty to Fill',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'shippedQty',
                    label: 'Fulfilled Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cancelledQty',
                    label: 'Cancelled Qty ',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'extendedPrice',
                    label: 'Extended Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'taxTotal',
                    label: 'Extended Tax',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'backorderedQty',
                    label: 'Back Order Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ],
            workOrderFields: [
                {
                    key: 'skuProduct',
                    label: 'Product SKU'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'type',
                    label: 'Variants',
                    formatter: (value, key, item) => {
                        if (item.bookVariant == 1 || item.bookVariant == 2) {
                            return bookVariantFormatter(item.bookVariant);
                        } else {
                            return (
                                item.gmVariant1 +
                                (item.gmVariant1 ? '/' : '') +
                                item.gmVariant2
                            );
                        }
                    }
                },
                {
                    key: 'retailPrice',
                    label: 'Item Price',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'orderQty',
                    label: 'Qty to Fill',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'shippedQty',
                    label: 'Fulfilled Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cancelledQty',
                    label: 'Cancelled Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'tax',
                    label: 'Ext. Tax',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'extendedPrice',
                    label: 'Extended Price',
                    formatter: (value, key, item) => {
                        return currencyFormatter(
                            (item.isOnSale
                                ? item.itemPrice
                                : item.retailPrice) * item.shippedQty
                        );
                    },
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'backorderedQty',
                    label: 'Back Order Qty',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
            ],
            isTaxRefresh: false
        };
    },
    watch: {
        webOrder: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        isTaxExempt: {
            handler: function(newVal) {
                if (newVal) {
                    this.clearTaxes();
                } else {
                    this.refreshTaxes();
                }
            }
        }
    },
    computed: {
        calcShippedQty() {
            return this.workOrder.partialSales.reduce(
                (a, b) => a + b.shippedQty,
                0
            );
        },
        calcWorkOrderSubTotal() {
            return roundingFormatter(
                this.workOrder.partialSales.reduce(
                    (a, b) =>
                        a +
                        (b.isOnSale ? b.itemPrice : b.retailPrice) *
                            b.shippedQty,
                    0
                ),
                2
            );
        },
        calcWorkOrderItemTaxTotal() {
            if (this.webOrder.isTaxExempt) {
                return roundingFormatter(0, 2);
            }
            return roundingFormatter(
                this.workOrder.partialSales.reduce((a, b) => {
                    if (b.taxOverride != null) {
                        let perItemTax = this.getItemTax(b) / b.webOrderQty;
                        return a + perItemTax * b.shippedQty;
                    } else {
                        return a + (b.taxTotal / b.webOrderQty) * b.shippedQty;
                    }
                }, 0),
                2
            );
        },
        calcWorkOrderTotal() {
            return this.calcShippedQty > 0
                ? this.calcWorkOrderSubTotal +
                      this.workOrderShippingFees +
                      this.workOrder.manualOverrideUserInput +
                      this.calcWorkOrderItemTaxTotal
                : 0;
        },
        disabledProcess() {
            return (
                this.workOrder.partialSales.length == 0 ||
                this.calcWorkOrderTotal < 0 ||
                (this.calcWorkOrderTotal > 0 && this.failedAuthAttempts >= 5)
            );
        },
        getLastUpdateDate() {
            let lastUpdate = null;
            if (this.webOrder.lastUpdateDate) {
                lastUpdate = dateFormatter(this.webOrder.lastUpdateDate);
            }
            return lastUpdate;
        },
        canAddProducts() {
            return (
                this.webOrder?.webOrderStatus.toLowerCase() == 'open' ||
                this.webOrder?.webOrderStatus.toLowerCase() == 'partial' ||
                this.webOrder?.webOrderStatus.toLowerCase() == 'failed' ||
                this.webOrder?.webOrderStatus.toLowerCase() == 'backordered'
            );
        },
        canAddShippingFee() {
            return (
                this.calcShippedQty > 0 &&
                (this.webOrder?.webOrderStatus.toLowerCase() == 'open' ||
                    this.webOrder?.saleShipments.length == 0)
            );
        },
        isTaxExempt() {
            return this.webOrder?.isTaxExempt ?? false;
        }
    },
    methods: {
        addressFormatter: addressFormatter,
        calcExtTaxPartialSale(item) {
            let perItemTax = this.getItemTax(item) / item.webOrderQty;
            return roundingFormatter(perItemTax * item.orderQty, 2);
        },
        calculateExtendedTax(item) {
            let perItemTax = this.getItemTax(item) / item.webOrderQty;
            return roundingFormatter(perItemTax * item.shippedQty, 2);
        },
        webOrderpaymentMethod(item) {
            this.webOrder.paymentMethod.accountNumber = item.accountNumber;
            this.webOrder.paymentMethod.agencyName = item.accountName;
            this.webOrder.paymentMethod.selectedAccountBalanceId =
                item.accountBalanceId;
            this.webOrder.isARAccountValid = true;
            this.webOrder.paymentMethod.isCCAccount = false;
            this.webOrder.paymentMethod.isARAccount = true;
            this.webOrder.paymentMethod.paymentMethodName = item.paymentMethod;         
            this.webOrder.isTaxExempt = item.isTaxExempt;
            this.onSubmit();
        },
        getShippingTax(order) {
            return this.webOrder.isTaxExempt
                ? 0
                : order.shippingTaxOverride?.amount ?? order.shippingTax;
        },
        getItemTax(item) {
            return this.webOrder.isTaxExempt
                ? 0
                : item.taxOverride?.amount ?? item.taxTotal;
        },
        fulfilledQtyChanged(event, item) {
            if(this.workOrder.paymentMethod.dccIds.length > 0 && !this.workOrder.paymentMethod.dccIds.includes(item.dccId) && item.shippedQty !== 0) {
                item.shippedQty = 0;
                Vue.toasted.global.app_error(`Payment type does not allow the purchase of SKU ${item.skuProduct}: ${item.description}.`);
                return;
            }

            this.workOrderItemTaxTotal = this.calcWorkOrderItemTaxTotal;
            let perItemTax = this.getItemTax(item) / item.webOrderQty;
            item.manualOverrideUserInput = roundingFormatter(
                perItemTax * item.shippedQty,
                2
            );
            
            item.shippedQty = this.zeroIfNullFormatter(event);

            this.resizeSerialNumberArray(item, item.shippedQty);
        },
        zeroIfNullFormatter(value) {
            return value ?? 0;
        },
        getShippedQtyRules(item) {
            return `min_value:0|exceed-shipped-qty:${item.orderQty},${item.cancelledQty}`;
        },
        getCancelledQtyRules(item) {
            return `min_value:0|exceed-cancelled-qty:${item.orderQty},${item.shippedQty}`;
        },
        getBackOrderQtyRules(item) {
            return `min_value:0|exceed-back-order-qty:${item.orderQty},${item.cancelledQty},${item.shippedQty}`;
        },
        noQtyToFill() {
            return (
                this.workOrder.partialSales.reduce(
                    (acc, item) => acc + item.orderQty,
                    0
                ) == 0
            );
        },
        phoneFormatter: phoneFormatter,
        dateFormatter: dateFormatter,
        datetimeToLocalTimeFormatter: datetimeToLocalTimeFormatter,
        currencyFormatter: currencyFormatter,
        onSubmit: async function() {
            this.isBusy = true;            

            this.webOrder.ShippingFee =
                this.workOrderShippingFees > 0
                    ? this.workOrderShippingFees
                    : this.webOrder.shippingTotal;

            this.webOrder.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.webOrder.isDepartmentCharge = false;
            this.formIsDirty = false;
            axios
                .post('weborders', this.webOrder)
                .then(() => {
                        this.dataSaved();
                        this.$toasted.global
                            .app_success(
                                `Web Order '${this.webOrder.name}' saved successfully.`
                            )
                            .goAway(5000);                   
                })
                .finally(() => {                    
                    this.isBusy = false;
                    this.loadData();
                });
        },        
        async processWorkOrder() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            const totalShippedQty = this.workOrder.partialSales.reduce((sum, partialSale) => sum + partialSale.shippedQty, 0);
            const totalCancelledQty = this.workOrder.partialSales.reduce((sum, partialSale) => sum + partialSale.cancelledQty, 0);
            const totalBackorderedQty = this.workOrder.partialSales.reduce((sum, partialSale) => sum + partialSale.backorderedQty, 0);
            if(totalShippedQty + totalCancelledQty + totalBackorderedQty <= 0) {
                Vue.toasted.global.app_error(`No product quantities entered (Fulfilled Qty, Cancelled Qty, or Back Order Qty).  Web order cannot be processed.`);
                return;
            }

            this.isBusy = true;
            (this.workOrder.saleId = this.webOrder.saleId),
                (this.workOrder.name = this.webOrder.name),
                (this.workOrder.shippingTax =
                    this.calcShippedQty > 0
                        ? this.getShippingTax(this.workOrder)
                        : 0),
                (this.workOrder.shippingTotal =
                    this.calcShippedQty > 0 ? this.workOrderShippingFees : 0);
            this.workOrder.total = this.calcWorkOrderTotal;
            this.workOrder.itemTaxTotal = this.calcWorkOrderItemTaxTotal;
            this.workOrder.subTotal = this.calcWorkOrderSubTotal;
            this.workOrder.shippingInstructions = this.workOrderShippingInstructions;
            this.workOrder.specialComments = this.workOrderSpecialComments;
            this.workOrder.saleFulfillmentId = 0;
            this.workOrder.isTaxExempt = this.webOrder.isTaxExempt;

            axios
                .post('weborders/processweborder', this.workOrder)
                .then(resp => {
                    if (resp) {
                        if (
                            !resp.data.taxApiResult &&
                            !this.webOrder.isTaxExempt
                        ) {
                            this.$bvModal
                                .msgBoxConfirm(
                                    "Automated tax update is not available. Please select 'Back to Work Order' to verify values, or 'Continue Processing' to accept tax values currently shown on web order and proceed.",
                                    {
                                        okVariant: 'danger',
                                        okTitle: 'Continue Processing',
                                        cancelTitle: 'Back to Work Order'
                                    }
                                )
                                .then(value => {
                                    if (value) {
                                        this.isBusy = true;
                                        this.workOrder.isContinueProcessing = true;
                                        this.workOrder.saleFulfillmentId =
                                            resp.data.saleFulfillmentId;
                                        axios
                                            .post(
                                                'weborders/processweborder',
                                                this.workOrder
                                            )
                                            .then(resp => {
                                                if (resp) {
                                                    this.responseMessage(resp);
                                                }
                                            })
                                            .finally(() => {
                                                this.isBusy = false;
                                            });
                                    }
                                });
                        } else {
                            this.responseMessage(resp);
                        }
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        responseMessage(resp) {
            this.workOrder.isContinueProcessing = false;
            if (resp.data.posAPIResult) {
                this.loadData();
                this.$refs.workOrderModal.hide();
                this.$toasted.global
                    .app_success(
                        `Web Order '${this.webOrder.name}' processed successfully.`
                    )
                    .goAway(5000);
            } else {
                this.workOrder.saleFulfillmentId = resp.data.saleFulfillmentId;
                this.failedAuthAttempts = resp.data.failedAuthAttempts;
                this.processFailed = !resp.data.posAPIResult;
                this.requestedAmount = resp.data.requestedAmount;
                this.approvedAmount = resp.data.approvedAmount;
                this.webOrder.webOrderStatus = 'Failed';
                this.dataLoaded();
            }
        },
        processCancelShipment() {
            this.isBusy = true;
            this.$refs.workOrderModal.hide();
            axios
                .post('weborders/cancelshipment', this.webOrder)
                .then(() => {
                    this.$toasted.global
                        .app_success(
                            `Shipment processing was successfully cancelled.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.loadData();
                    this.isBusy = false;
                });
        },
        async handleCancelOrder(type) {
            this.isBusy = true;
            let hasBackOrderItems =
                this.webOrder.webOrderStatus.toLowerCase() == 'backordered' ||
                this.webOrder.webOrderStatus.toLowerCase() ==
                    'partial/backordered';

            if (hasBackOrderItems) {
                await this.$bvModal
                    .msgBoxConfirm(
                        `This Order has Backordered Items. Do you wish to continue cancelling?`
                    )
                    .then(value => {
                        if (value) {
                            this.cancelOrder(type);
                        } else {
                            this.isBusy = false;
                        }
                    });
            } else {
                await this.cancelOrder(type);
            }
        },

        handleReopen() {
            if (
                this.webOrder.webOrderStatus.toLowerCase() ==
                    'completed at register' ||
                this.webOrder.webOrderStatus.toLowerCase() == 'cancelled'
            ) {
                this.$bvModal
                    .msgBoxConfirm(
                        this.webOrder.webOrderStatus.toLowerCase() ==
                            'completed at register'
                            ? `This order may have already been completed at the register.  Are you sure you want to reopen it?`
                            : this.webOrder.webOrderStatus.toLowerCase() ==
                              'cancelled'
                            ? `This order was previously Canceled. Are you sure you want to reopen it?`
                            : '',
                        {
                            okTitle: 'Yes',
                            cancelTitle: 'No'
                        }
                    )
                    .then(value => {
                        if (value) {
                            this.workOrder.IsReopen = true;
                            this.workOrder.saleId = this.webOrder.saleId;
                            this.workOrder.name = this.webOrder.name;
                            this.workOrder.total = 0;
                            this.isBusy = true;

                            this.workOrder.saleFulfillmentId = 0;
                                         axios
                                            .post(
                                                'weborders/processweborder',
                                                this.workOrder
                                            )
                                            .then(resp => { 
                                                     if (resp.data.posAPIResult) {
                                                        
                                                        this.workOrder.IsReopen = false;
                                                        this.loadData();
                                                         this.$refs.workOrderModal.hide();
                                                         this.$toasted.global.app_success(
                                    `Web Order '${this.webOrder.name}' has been reopened successfully.`
                                ).goAway(5000);
                                                     }  
                                            })
                                            .finally(() => {
                                                this.workOrder.IsReopen = false;
                                                this.isBusy = false;
                                            });
                        }
                    })

                    
            }
        },
        async cancelOrder(type) {
            if (type === 'completeAtRegister') {
                this.workOrder.IsCompletedAtRegister = true;
            }
            this.workOrder.partialSales.forEach(x => {
                x.cancelledQty = x.orderQty;
                x.shippedQty = 0;
            });
            this.workOrder.saleId = this.webOrder.saleId;
            this.workOrder.name = this.webOrder.name;
            this.workOrder.total = 0;
            axios
                .post('weborders/processweborder', this.workOrder)
                .then(resp => {
                    if (resp) {
                        if (resp.data.posAPIResult) {
                            this.loadData();
                            if (type === 'completeAtRegister') {
                                this.$toasted.global
                                    .app_success(
                                        `Web Order '${this.webOrder.name}' has been marked as Completed at Register.`
                                    )
                                    .goAway(5000);
                            } else {
                                this.$toasted.global
                                    .app_success(
                                        `Web Order '${this.webOrder.name}' cancelled successfully.`
                                    )
                                    .goAway(5000);
                            }
                        }
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        handleAddCustomer() {
            if (!this.$refs.formModal.validate()) {
                return;
            }                     
            let promise = axios.get('customers', {
                params: { id: this.webOrder.customer.customerId }
            });
            promise.then(resp => {
                this.webOrder.customer.firstName = resp.data.firstName;
                this.webOrder.customer.lastName = resp.data.lastName;
                this.webOrder.customer.email = resp.data.email;
                this.webOrder.customer.phone.number =
                    resp.data.defaultPhone.number;
                this.webOrder.customer.number = resp.data.number;
                if(this.webOrder.paymentMethod.isARAccount){
                    this.webOrder.paymentMethod.accountNumber = null;
                    this.webOrder.paymentMethod.agencyName = null; 
                    this.webOrder.paymentMethod.selectedAccountBalanceId = 0;           
                    this.webOrder.isARAccountValid = false;
                }                
                this.$refs.editCustomerModal.hide();
                this.onSubmit();
            });
        },
        showModal() {
            this.customerId = this.webOrder.customer.customerId;
        },
        showWorkOrderModal() {
            this.workOrder.partialSales.forEach(partialSale => {
                Vue.set(
                    partialSale,
                    'manualOverrideUserInput',
                    roundingFormatter(this.calculateExtendedTax(partialSale), 2));

                // Set the initial shipped quantity based on whether the payment method's Dccs match the partial sale's product's Dcc
                partialSale.shippedQty = this.fulfillmentAllowedForPaymentMethod(partialSale, this.workOrder) ? partialSale.orderQty : 0;
                partialSale.cancelledQty = 0;
                partialSale.backorderedQty = 0;
                this.resizeSerialNumberArray(partialSale, partialSale.shippedQty);
            });

            //only add shipping on first shipment.
            this.workOrderShippingFees = this.canAddShippingFee
                ? this.webOrder.shippingTotal
                : 0;
            this.workOrderShippingTax = this.canAddShippingFee
                ? this.getShippingTax(this.workOrder)
                : 0;
            Vue.set(
                this.workOrder,
                'manualOverrideUserInput',
                this.canAddShippingFee ? this.getShippingTax(this.workOrder) : 0
            );

            this.workOrderItemTaxTotal = this.calcWorkOrderItemTaxTotal;
            this.workOrderShippingInstructions = this.webOrder.shippingInstructions;
            this.workOrderSpecialComments = this.webOrder.specialComments;
        },
        fulfillmentAllowedForPaymentMethod(partialSale, workOrder) {
            if(workOrder.paymentMethod.dccIds.length === 0) 
                return true; // Payment method has no Dccs assigned, so all products are valid for purchase
            
            if(workOrder.paymentMethod.dccIds.includes(partialSale.dccId))
                return true; // A payment method Dcc matches the partial sale Dcc, and is valid for purchase
            
            return false; // No payment method Dcc matches the partial sale Dcc, so the partial sale product is not valid for purchase
        },        
        hideWorkOrderModal() {
            this.loadData();
        },
        handleItemTaxOverride(event, item) {
            //save original value incase they decide to cancel the tax override.
            var oldTaxAmount = roundingFormatter(
                item.manualOverrideUserInput,
                2
            );

            //if the values are different, update. If not return leaving the value as is.
            if (event != oldTaxAmount) {
                item.manualOverrideUserInput = event;
            } else {
                return;
            }

            if (oldTaxAmount == null) {
                item.manualOverrideUserInput = this.getItemTax(item);
                return;
            }

            let confirmMessage = item.taxOverrideId
                ? 'Are you sure you want to update the Tax Override for this item?'
                : 'Are you sure you want to create a Tax Override for this item?';

            this.$bvModal.msgBoxConfirm(confirmMessage).then(value => {
                if (value) {
                    this.isBusy = true;
                    let taxOverrideDto = {
                        amount: this.zeroIfNullFormatter(
                            item.manualOverrideUserInput
                        ),
                        saleItemId: item.saleItemId
                    };
                    axios
                        .post('taxes/saleitemtaxoverride', taxOverrideDto)
                        .then(resp => {
                            if (item.taxOverride) {
                                item.taxOverride.amount = taxOverrideDto.amount;
                            } else {
                                item.taxOverride = resp.data;
                            }
                            let perItemTax =
                                item.taxOverride.amount / item.webOrderQty;
                            item.manualOverrideUserInput = roundingFormatter(
                                perItemTax * item.shippedQty,
                                2
                            );

                            let webOrderItem = this.webOrder.products.find(
                                x => x.skuProduct == item.skuProduct
                            );
                            if (webOrderItem) {
                                webOrderItem.taxTotal = item.taxOverride.amount;
                            }
                            this.dataLoaded();
                            this.$toasted.global
                                .app_success(`Tax Override saved successfully.`)
                                .goAway(5000);
                        })
                        .finally(() => {
                            this.isBusy = false;
                        });
                } else {
                    item.manualOverrideUserInput = oldTaxAmount;
                }
            });
        },
        handleShippingTaxOverride(event, workOrder) {
            if (workOrder.manualOverrideUserInput == null) {
                workOrder.manualOverrideUserInput = this.workOrderShippingTax;
                return;
            }

            if (
                workOrder.manualOverrideUserInput == this.workOrderShippingTax
            ) {
                return;
            }

            let oldTaxOverride = JSON.parse(
                JSON.stringify(workOrder.shippingTaxOverride)
            );
            workOrder.shippingTaxOverride = {
                saleItemTaxOverrideId: workOrder.shippingTaxOverrideId,
                amount: this.zeroIfNullFormatter(
                    workOrder.manualOverrideUserInput
                )
            };
            let confirmMessage = workOrder.shippingTaxOverrideId
                ? 'Are you sure you want to update the Tax Override for shipping tax?'
                : 'Are you sure you want to create a Tax Override for shipping tax?';

            this.$bvModal.msgBoxConfirm(confirmMessage).then(value => {
                if (value) {
                    this.isBusy = true;
                    let taxOverrideDto = {
                        amount: this.zeroIfNullFormatter(
                            workOrder.manualOverrideUserInput
                        ),
                        saleItemId: this.webOrder.shippingTaxItemId
                    };
                    axios
                        .post('taxes/saleitemtaxoverride', taxOverrideDto)
                        .then(resp => {
                            this.webOrder.shippingTax = this.workOrderShippingTax =
                                resp.data.amount;
                            this.dataLoaded();
                            this.$toasted.global
                                .app_success(`Tax Override saved successfully.`)
                                .goAway(5000);
                        })
                        .finally(() => {
                            this.isBusy = false;
                        });
                } else {
                    workOrder.taxOverride = JSON.parse(
                        JSON.stringify(oldTaxOverride)
                    );
                    workOrder.manualOverrideUserInput = this.workOrderShippingTax;
                }
            });
        },
        processShipment() {
            axios.post('weborders/processshipment', this.webOrder).then(() => {
                this.$toasted.global
                    .app_success(
                        `Shipment '${this.webOrder.name}' processed successfully.`
                    )
                    .goAway(5000);
            });
        },
        refreshAllTaxes() {
            this.$bvModal
                .msgBoxConfirm(
                    'This will refresh all tax values on the current screen, including overridden values.'
                )
                .then(value => {
                    if (value) {
                        this.refreshTaxes();
                    }
                });
        },
        async refreshTaxes() {
            this.webOrder.isTaxRefresh = true;
            this.isTaxRefresh = true;

            this.isRefreshBusy = true;

            this.webOrder.ShippingFee =
                this.workOrderShippingFees > 0
                    ? this.workOrderShippingFees
                    : this.webOrder.shippingTotal;

            this.webOrder.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.webOrder.isDepartmentCharge = false;            
            this.formIsDirty = false;
            axios
                .post('weborders/refreshtaxweborder', this.webOrder)                
                .finally(() => {                
                    this.isRefreshBusy = false;
                    this.loadData();
                });
        },
        clearTaxes() {
            if (this.webOrder.webOrderStatus.toLowerCase() == 'open') {
                this.webOrder.products.forEach(x => (x.taxTotal = 0));
                this.webOrder.partialSales.forEach(x => (x.taxTotal = 0));
                this.workOrder.partialSales.forEach(x => (x.taxTotal = 0));

                this.webOrder.shippingTax = 0;
                this.webOrder.extendedTaxTotal = 0;
                this.webOrder.taxTotal = 0;
                this.webOrder.total =
                    this.webOrder.subtotal + this.webOrder.shippingTotal;
            }
        },
        async loadData() {
            this.isBusy=true;
            this.isRefreshBusy=true;
            axios
                .get('weborders', {
                    params: {
                        locationId: await selectListOptionsDataContext.getSelectedLocationIdAsync(),
                        id: this.id
                    }
                })
                .then(resp => {
                    this.webOrder = resp.data;
                    this.webOrder.products.forEach(x =>
                        Vue.set(x, '_showDetails', true)
                    );
                    this.webOrder.partialSales.forEach(x =>
                        Vue.set(x, '_showDetails', true)
                    );
                    this.webOrder.saleShipments.forEach(x =>
                        Vue.set(x, '_showDetails', true)
                    );
                    if (this.webOrder.webOrderStatus.toLowerCase() != 'open') {
                        this.workOrder.partialSales = [
                            ...this.webOrder.partialSales.map(x => ({ ...x }))
                        ];
                    } else {
                        this.workOrder.partialSales = [
                            ...this.webOrder.products.map(x => ({ ...x }))
                        ];
                    }
                    this.workOrder.partialSales.forEach(x => {
                        (x.shippedQty = x.orderQty),
                            (x.cancelledQty = 0),
                            (x.backorderedQty = 0);
                    });

                    this.workOrder.shippingTax = this.webOrder.shippingTax;
                    this.workOrder.shippingTaxOverride = this.webOrder.shippingTaxOverride;
                    this.workOrder.shippingTaxOverrideId = this.webOrder.shippingTaxOverrideId;

                    this.failedAuthAttempts = this.webOrder.failedAuthAttempts;
                    if (this.webOrder.failedAuthAttempts == 5) {
                        this.processFailed = true;
                    }
                    if (this.isTaxRefresh) {
                        this.refreshIsBusy = true;
                        this.showWorkOrderModal();
                        this.isTaxRefresh = false;
                    }

                    this.workOrder.paymentMethod = this.webOrder.paymentMethod;

                    this.dataLoaded();
                })
                .finally(() => {
                    this.isBusy=false;
                    this.isRefreshBusy=false;
                });
        },
        exportShipment(saleShipment, option) {
            if (option == 0) {
                this.isReceiptBusy = true;
            } else {
                this.isPackingBusy = true;
            }

            this.filters.saleId = saleShipment.saleId;
            this.filters.saleFulfillmentId = saleShipment.saleFulfillmentId;
            this.filters.exportOption = option;

            axios
                .post('/weborders/exportshipment', this.filters, {
                    responseType: 'blob'
                })
                .then(result => {
                    if (option == 0) {
                        downloadFileToBrowser(
                            result.data,
                            'Web Shipment Receipt ' +
                                saleShipment.saleFulfillmentName,
                            'application/pdf'
                        );
                        this.$toasted.global
                            .app_success(
                                'Web Shipment Receipt ' +
                                    saleShipment.saleFulfillmentName,
                                ' generated successfully.'
                            )
                            .goAway(5000);
                    } else {
                        downloadFileToBrowser(
                            result.data,
                            'Web Packing Slip ' +
                                saleShipment.saleFulfillmentName,
                            'application/pdf'
                        );
                        this.$toasted.global
                            .app_success(
                                'Web Packing Slip ' +
                                    saleShipment.saleFulfillmentName,
                                ' generated successfully.'
                            )
                            .goAway(5000);
                    }
                })
                .finally(() => {
                    if (option == 0) {
                        this.isReceiptBusy = false;
                    } else {
                        this.isPackingBusy = false;
                    }
                });
        },
        addSelectedItems: function(items) {
            this.isBusy = true;
            // now I have questions about how we deal with locations...
            // I'd also really like to extract this logic to a class...
            let details = items.map(item => ({
                backorderedQty: 0,
                bookVariant: item.bookVariant,
                cancelledQty: 0,
                comments: '',
                saleItemId: null,
                shippedQty: 0,
                defaultBarcode: item.barcodes,
                description: item.authorTitleOrDescription,
                retailPrice: item.retail,
                extendedPrice:
                item.retail *
                    (item.orderQty == null || item.orderQty == 0
                        ? 1
                        : item.orderQty + 1),
                gmVariant1: item.gmVariant1 ?? '',
                gmVariant2: item.gmVariant2 ?? '',
                isbn: item.isbn,
                skuProduct: item.sku,
                specialItemId: null,
                taxTotal: item?.Value ?? 0,
                saleId: this.webOrder.saleId,
                isSerialized: false,
                dccId: item.dccId
            }));

            // Validate that the added products are fulfillable by matching the payment method's DCCs with the product's DCC
            for(const detail of details) {
                if(this.workOrder.paymentMethod.dccIds.length > 0 && !this.workOrder.paymentMethod.dccIds.includes(detail.dccId)) {
                    Vue.toasted.global.app_error(`Payment type does not allow the purchase of at least one selected product, SKU ${detail.skuProduct}: ${detail.description}.`)
                    this.isBusy = false;
                    return;
                }
            }

            details.forEach(x => {
                var product = this.webOrder.products.find(
                    a => a.skuProduct == x.skuProduct
                );
                if (product != null) {
                    product.orderQty += 1;
                    product.extendedPrice =
                        product.orderQty *
                        (product.isOnSale
                            ? product.itemPrice
                            : product.retailPrice);
                } else {
                    x.orderQty = 1;
                }
            });

            this.webOrder.products = unionBy(
                this.webOrder.products,
                details,
                x => x.skuProduct
            );
            this.onSubmit();
        },
        validateWorkOrder: function() {
            if (this.formIsDirty) {
                Vue.toasted.global.app_error(
                    'Please save or discard changes by reloading the page before working order'
                );
            } else {
                this.$refs.workOrderModal.show();
            }
        },
        async updateSerialValidation(product, value, item) {
            this.isBusy = true;
            if (value) {
                if (product.serialNumbers) {
                    Vue.set(
                        item,
                        'isUnique',
                        product.serialNumbers.filter(
                            x => x.serialNumber === value
                        ).length <= 1
                    );
                }

                let isValid = false;
                let resp = await axios.get('/weborders/validateserialnumber', {
                    params: {
                        locationId: await selectListOptionsDataContext.getSelectedLocationIdAsync(),
                        sku: product.skuProduct,
                        serialNumber: value
                    }
                });

                if (resp && resp.data && resp.data.isValid) {
                    isValid = true;
                    resp.data.serialCustomer.serialNumber = value;
                    let index = product.serialNumbers.findIndex(
                        x => x.serialNumber == value
                    );
                    Object.assign(
                        product.serialNumbers[index],
                        resp.data.serialCustomer
                    );
                }
                Vue.set(item, 'isValid', isValid);
            }
            this.isBusy = false;
        },
        resizeSerialNumberArray(productItem, size) {
            if (productItem.isSerialized) {
                if (size <= 0) {
                    if (productItem.serialNumbers) {
                        productItem.serialNumbers.splice(
                            0,
                            productItem.serialNumbers.length
                        );
                    }
                    return;
                }

                if (!productItem.serialNumbers) {
                    productItem.serialNumbers = [];
                }

                let delta = productItem.serialNumbers.length - size;

                //remove empty rows first
                if (delta > 0) {
                    for (
                        let i = productItem.serialNumbers.length - 1;
                        i >= 0 && delta > 0;
                        i--
                    ) {
                        if (!productItem.serialNumbers[i].serialNumber) {
                            productItem.serialNumbers.splice(i, 1);
                            delta--;
                        }
                    }
                    while (delta-- > 0) {
                        productItem.serialNumbers.pop();
                    }
                    return;
                }

                while (delta++ < 0) {
                    productItem.serialNumbers.push({
                        serialCustomerId: null,
                        serialLocationId: null,
                        customerId: null,
                        mfgXref: null,
                        xref: null,
                        isAvailable: null,
                        serialNumber: null
                    });
                }
            }
        }
    },
    mounted: function() {
        this.loadData();
    },
    components: {
        ProductMultiSelectQuickSearch,
        ARAccountsModal,
        DepartmentChargeModal
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}

/deep/ .tcw200 {
    width: 200px;
}
.redColor {
    color: red;
}
</style>

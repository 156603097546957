<template>
    <div class="container-fluid content-wrapper" v-if="invoice">
        <p-form @submit="onSubmit" id="invoice-form">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Invoice</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="auto" class="mr-auto mb-2 d-flex" v-if="!isNew">
                    <b-dropdown
                        class="mr-2"
                        :text="`Purchase Orders (${allPurchaseOrders.length})`"
                        variant="outline-primary"
                    >
                        <b-dropdown-item
                            v-for="purchaseOrder in allPurchaseOrders"
                            :key="purchaseOrder.purchaseOrderId"
                            @click="
                                loadPurchaseOrder(purchaseOrder.purchaseOrderId)
                            "
                        >
                            {{ purchaseOrder.poNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                        :text="`Credit Requests (${allReturns.length})`"
                        variant="outline-primary"
                        class="mr-2"
                    >
                        <b-dropdown-item
                            v-for="creditRequest in allReturns"
                            :key="creditRequest.creditRequestId"
                            @click="
                                loadCreditRequest(creditRequest.creditRequestId)
                            "
                        >
                            {{ creditRequest.requestNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        type="button"
                        @click="exportInvoice"
                        >Export Invoice</p-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <Invoice 
                    :invoice="invoice" 
                    :is-busy="isBusy" 
                    @date-picker-initialized="e => {loadData()}"
                    @save-adjustments="onSubmit()"
                />
            </p-card>
            <b-row>
                <b-col class=" mt-3 text-center">
                    <p-button
                        variant="primary"
                        type="submit"
                        form="invoice-form"
                        :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <div>
                <p-modal
                    id="ProductUpdateModal"
                    size="lg"
                    no-close-on-backdrop
                    no-close-esc
                    name="ProductUpdateModal"
                    label="Product Update Modal"
                    ref="productUpdateModal"
                    @shown="productUpdateModalOnShow"
                    v-on:ok="updateProductUpdateSelections">
                    <template v-slot:modal-title>
                        <h3>{{ productUpdateModalTitle }}</h3>
                    </template>
                    <template v-slot:default>
                        <div>{{ productUpdateModalDescription }}</div>
                        <p-form ref="form">
                            <div>
                                <b-row>
                                    <b-col>
                                        <p-table
                                            ref="updateProductsTable"
                                            :items="changeProducts"
                                            :fields="changeFields"
                                            :enableRowSelection="true"
                                            :per-page="10"
                                            class="wide table-striped">
                                        </p-table>
                                    </b-col>
                                </b-row>
                            </div>
                        </p-form>
                    </template> 
                    <template v-slot:modal-footer="{ ok, cancel }">
                        <b-row>
                            <b-col>
                                <p-button :is-busy="isBusy" variant="outline-primary" @click="cancel">{{ productUpdateModalCancelButtonText }}</p-button>
                                <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">{{ productUpdateModalOkButtonText }}</p-button>
                            </b-col>
                        </b-row>                        
                    </template>
                 </p-modal>
            </div>
        </p-form>
    </div>
</template>

<script>
import { currencyFormatter } from '@/components/Common/Formatters';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import Invoice from '@/views/PurchaseOrders/Invoice.vue';
import axios from 'axios';
import editPage from '@/components/mixins/EditPage';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [NavigationGuard, editPage],
    components: {
        Invoice
    },
    data() {
        return {
            invoice: null,
            isBusy: false,
            productUpdateType: null,
            changeProducts: [],
            changeFields: [],
            costChangeProducts:[],
            priceChangeProducts: [],
            costChangeFields: [
                {
                    key: 'sku',
                    label: 'Sku',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },
                {
                    key: 'description',
                    label: 'Description',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },  
                {
                    key: 'termName',
                    label: 'Term',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },                                 
                {
                    key: 'invoiceCost',
                    label: 'Invoice Cost',
                    thClass: 'text-left',
                    tdClass: 'text-left',
                    formatter: currencyFormatter
                },
                {
                    key: 'productCost',
                    label: 'Product Cost',
                    thClass: 'text-left',
                    tdClass: 'text-left',
                    formatter: currencyFormatter,
                },
                {
                    key: 'stockOnHand',
                    label: 'StockOnhand',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },
                {
                    key: 'termId',
                    thClass: 'd-none',
                    tdClass: 'd-none'                    
                },   
            ],
            priceChangeFields: [
                {
                    key: 'sku',
                    label: 'Sku',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },
                {
                    key: 'description',
                    label: 'Description',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },
                {
                    key: 'termName',
                    label: 'Term',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },                                  
                {
                    key: 'invoicePrice',
                    label: 'Invoice Price',
                    thClass: 'text-left',
                    tdClass: 'text-left',
                    formatter: currencyFormatter
                },
                {
                    key: 'productPrice',
                    label: 'Product Price',
                    thClass: 'text-left',
                    tdClass: 'text-left',
                    formatter: currencyFormatter,
                },
                {
                    key: 'stockOnHand',
                    label: 'StockOnhand',
                    thClass: 'text-left',
                    tdClass: 'text-left'
                },
                {
                    key: 'termId',
                    thClass: 'd-none',
                    tdClass: 'd-none'                    
                },                                                           
            ],            
        };
    },
    watch: {
        invoice: NavigationGuard.$watcher
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        allReturns() {
            let creditRequests = this.invoice.invoiceDetails.flatMap(
                x => x.creditRequestDetails
            );
            return creditRequests
                .filter(
                    (e, i) =>
                        creditRequests.findIndex(
                            a => a.creditRequestId === e.creditRequestId
                        ) === i
                )
                .sort((a, b) => {
                    return a.requestNumber.localeCompare(b.requestNumber);
                });
        },
        allPurchaseOrders() {
            let purchaseOrders = this.invoice.invoiceDetails.map(x => ({
                purchaseOrderId: x.purchaseOrderId,
                poNumber: x.purchaseOrder
            }));
            return purchaseOrders
                .filter(
                    (e, i) =>
                        purchaseOrders.findIndex(
                            a => a.purchaseOrderId === e.purchaseOrderId
                        ) === i
                )
                .sort((a, b) => {
                    return a.poNumber.localeCompare(b.poNumber);
                });
        },        
        isExpenses() {
            return this.id === 'newexp';
        },
        isNew() {
            return this.id === 'new' || this.id === 'newexp';
        }
    },
    methods: {
        loadCreditRequest(id) {
            this.$router.push(`/creditrequests/${id}`);
        },
        loadPurchaseOrder(id) {
            this.$router.push(`/purchaseOrders/${id}`);
        },
        async onSubmit() {
            await this.displayCostChangeModal();
            await this.displayPriceChangeModal();

            this.isBusy = true;
            axios
                .post('invoices', this.invoice)
                .then(response => {
                    if (this.isNew) {
                        this.dataSaved();
                        this.$router.push(
                            '/invoices/' + response.data.invoiceId
                        );
                    }

                    this.invoice = response.data;
                    this.dataLoaded();
                    this.$toasted.global
                        .app_success(
                            `Invoice '${this.invoice.invoiceNumber}' saved successfully.`
                        )
                       .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        productUpdateModalOnShow() {
            this.$refs.updateProductsTable.selectAll();

            const selectedRows = this.$refs.updateProductsTable.selectedRows;
            const uniqueRows = new Map();

            // If there are multiple SKUs in the selected rows, only check the one with the highest cost or price
            selectedRows.forEach(currentRow => {
                const existingRow = uniqueRows.get(currentRow.sku);

                if (!existingRow || 
                    (currentRow.invoiceCost && currentRow.invoiceCost > existingRow.invoiceCost) || 
                    (currentRow.invoicePrice && currentRow.invoicePrice > existingRow.invoicePrice)) {
                    uniqueRows.set(currentRow.sku, currentRow);
                }
            });

            this.$refs.updateProductsTable.selectedRows = Array.from(uniqueRows.values());
        },
        updateProductUpdateSelections() {
            const selectedRows = this.$refs.updateProductsTable.selectedRows;
            selectedRows.forEach(selectedRow => {
                const detail = this.invoice.invoiceDetails.find(detail => detail.sku === selectedRow.sku && detail.termId === selectedRow.termId);
                if (detail) {
                    (detail.productInventoryUpdate ??= {})[this.productUpdateType] = true;
                }
            });   
        },
        async displayCostChangeModal() {
            // Cost changes between invoices and products
            this.costChangeProducts = this.invoice.invoiceDetails
                .filter(detail => detail.cost !== detail.productInventory.cost)
                .map(detail => {
                    return {
                        sku: detail.sku,
                        description: detail.description,
                        termName: detail.termName,
                        termId: detail.termId,
                        invoiceCost: detail.cost,
                        productCost: detail.productInventory.cost,
                        stockOnHand: detail.productInventory.onHandQty
                    };
                });

            if (this.costChangeProducts.length > 0) {
                this.changeProducts = this.costChangeProducts;
                this.changeFields = this.costChangeFields;
                await this.showUpdateModal('Cost');
            }
        },
        async displayPriceChangeModal() {
            // Price changes between invoices and products
            this.priceChangeProducts = this.invoice.invoiceDetails
                .filter(detail => detail.retail > 0 && detail.retail !== detail.productInventory.retail)
                .map(detail => {
                    return {
                        sku: detail.sku,
                        description: detail.description,
                        termName: detail.termName,
                        termId: detail.termId,
                        invoicePrice: detail.retail,
                        productPrice: detail.productInventory.retail,
                        stockOnHand: detail.productInventory.onHandQty
                    };
                });

            if (this.priceChangeProducts.length > 0) {
                this.changeProducts = this.priceChangeProducts;
                this.changeFields = this.priceChangeFields;
                await this.showUpdateModal('Price');
            }
        },
        async showUpdateModal(updateType) {            
            this.productUpdateModalTitle = `Product ${updateType} Update`;
            this.productUpdateModalDescription = `The Invoice ${updateType} of some products has changed. Do you want to update the Product ${updateType} with the Invoice ${updateType}?`;
            this.productUpdateModalCancelButtonText = `Disregard ${updateType} changes`;
            this.productUpdateModalOkButtonText = 'Update selected Products';
            this.productUpdateType = `update${updateType}`; // updateCost or updatePrice

            // Causes the modals to be shown serially before save of invoice.  Note you cannot display a modal while another modal exists, so the functionality has to be tied to the modal's hidden event
            return new Promise((resolve) => {
                this.$root.$on('bv::modal::hidden', () => {
                    resolve();
                });
                this.$bvModal.show('ProductUpdateModal');
            });
        },
        loadData() {
            let promise = this.isNew
                ?  (this.isExpenses ? axios.get('invoices/newexp') : axios.get('invoices/new'))
                : axios.get('invoices', { params: { id: this.id } });

            promise.then(resp => {
                this.invoice = resp.data;

                this.dataLoaded();
                this.invoice.previousStatus = this.invoice.status;
            });
        },
        exportInvoice() {
            this.isBusy = true;
            axios
                .get('invoices/exportInvoice', {
                    params: { invoiceId: this.id },
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Invoice',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(`Invoice generated successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>
